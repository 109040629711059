<script setup lang="ts">
// Get the current route
const route = useRoute()
const baseUrl = useRequestURL().origin

// Construct the canonical URL dynamically
const canonicalUrl = baseUrl + route.fullPath

// Set the head elements, including the canonical link
useHead({
  link: [
    {
      rel: 'canonical',
      href: canonicalUrl
    }
  ]
})

const { data: navigation } = await useAsyncData('navigation', () => fetchContentNavigation(), { default: () => [] })
provide('navigation', navigation)
</script>

<template>
  <v-app>
    <div class="background">
      <Header />
      <UMain>
        <slot />
      </UMain>
      <Footer />
    </div>
  </v-app>
</template>